$font-family-body: 'motiva-sans'!important;;

$font-color: #221F1F;
$link-color: #027A88;
$pledge-btn-color: #EE4B4C;

.SuperHeroPledge {
    &--App {
        color: $font-color;
        font-family: $font-family-body;
        line-height: 1.2;
        padding: 40px 24px;
        border-radius: 12px;
        background-color: #fff;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.15);

        @media screen and (min-width: 992px) {
            padding: 75px 160px;
        }

        * {
            box-sizing: border-box;
        }

        a {
            color: $link-color;
            text-decoration: none;

            &:hover {
                color: darken($link-color, 30%);
                text-decoration: underline;
            }
        }

        .hidden-desktop {
            @media screen and (min-width: 768px) {
                display: none;
            }
        }

        .hidden-mobile {
            @media screen and (max-width: 767px) {
                display: none;
            }
        }

        &-layout {
            display: flex;
            row-gap: 24px;
            column-gap: 42px;
            align-items: center;
            flex-direction: column;

            @media screen and (min-width: 768px) {
                flex-direction: row;
            }
        }

        &-layoutDivider {
            width: 100%;
            height: 1px;
            align-self: stretch;
            background-color: rgba(0, 0, 0, 0.20);

            @media screen and (min-width: 768px) {
                width: 1px;
                height: unset;
            }
        }

        &-header {
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            line-height: 1.4;
            margin-bottom: 20px;

            @media screen and (min-width: 768px) {
                font-size: 36px;
            }
        }

        &-subHeader {
            font-size: 18px;
            font-weight: 300;
            margin: 0 0 48px;
            line-height: 1.4;

            @media screen and (min-width: 768px) {
                font-size: 22px;
                line-height: 36px;
            }

            strong {
                font-weight: 700;
            }

            ul {
                list-style: none;
                padding-left: 30px;
                margin: 20px 0;

                li {
                    position: relative;

                    &:before {
                        width: 20px;
                        height: 20px;
                        content: " ";
                        display: block;
                        position: absolute;
                        right: 100%;
                        margin: 2px 10px 0 0;
                        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path fill="%23ee4b4c" d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>');
                        background-size: cover;
                        background-repeat: no-repeat;

                        @media screen and (min-width: 768px) {
                            margin: 6px 10px 0 0;
                        }
                    }
                }
            }
        }

        &-header,
        &-subHeader {
        }

        &-footer {
            font-size: 13px;
            font-weight: 300;
            font-style: normal;
            line-height: 20px;
            margin-top: 32px;
            text-align: center;

            @media screen and (min-width: 768px) {
                font-size: 15px;
                line-height: 24px;
                margin-top: 48px;
            }
        }
    }

    &--Pledge {
        &-container {
            display: flex;
            flex-direction: column;
            gap: 16px;

            @media screen and (min-width: 768px) {
                flex: 0 0 260px;
            }

            @media screen and (min-width: 1024px) {
                flex: 0 0 360px;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    &--Form {
        --pledge-gutter-x: 30px;
        --pledge-gutter-y: 30px;
        --pledge-border-radius: 0;
        --pledge-border-width: 1px;
        --pledge-border-color: #DDD;

        .row {
            display: flex;
            flex-wrap: wrap;
            margin-top: calc(-1 * var(--pledge-gutter-y));
            margin-right: calc(-.5 * var(--pledge-gutter-x));
            margin-left: calc(-.5 * var(--pledge-gutter-x));

            &>* {
                flex-shrink: 0;
                width: 100%;
                max-width: 100%;
                padding-right: calc(var(--pledge-gutter-x) * .5);
                padding-left: calc(var(--pledge-gutter-x) * .5);
                margin-top: var(--pledge-gutter-y);
            }
        }

        .col {
            flex: 0 0 auto;
            width: 100%;

            @media screen and (min-width: 768px) {
                flex: 1 0 0%;
            }

            &-12 {
                flex: 0 0 auto;
                width: 100%;
            }
        }

        .form-control {
            display: block;
            width: 100%;
            padding: 0.375rem 0.75rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: $font-color;
            appearance: none;
            background-clip: padding-box;
            border: var(--pledge-border-width) solid var(--pledge-border-color);
            border-radius: var(--pledge-border-radius);
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }

        &-intro {
            font-size: 18px;
            font-weight: 300;
            font-style: normal;
            margin: 20px 0 42px;
            line-height: 36px;

            @media screen and (min-width: 768px) {
                font-size: 22px;
            }
        }

        &-header {
            font-size: 24px;
            font-weight: 700;
            font-style: normal;
            margin-bottom: 30px;

            @media screen and (min-width: 768px) {
                font-size: 36px;
            }

            hr {
                width: 100px;
                margin: 20px auto;
                border-width: 2px;
                border-color: #FE9B8F;
                border-top: none;
            }
        }

        &-give {
            &-header {
                font-size: 24px;
                font-weight: 700;
                margin: 40px 0;
                text-align: center;

                @media screen and (min-width: 768px) {
                    font-size: 32px;
                }
            }

            &-wrapper {
                display: flex;
                justify-content: center;
                row-gap: 20px;
                column-gap: 40px;
                flex-direction: column;

                @media screen and (min-width: 768px) {
                    flex-direction: row;
                }
            }

            &-col {
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                align-items: center;
            }

            &-img {

                img {
                    max-width: 200px;
                    height: auto;

                    @media screen and (min-width: 1024px) {
                        max-width: 280px;
                    }
                }
                
            }

            &-heading {
                color: #333;
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                margin: 16px 0 8px;

                @media screen and (min-width: 1024px) {
                    font-size: 32px;
                    margin: 24px 0 8px;
                }
            }
            
            &-desc {
                font-size: 16px;
                font-weight: 400;
                margin: 0 0 20px;
                text-align: center;
            }

            &-btn {
                margin-top: auto;

                // a {
                //     color: #fff;
                //     font-size: 16px;
                //     font-weight: 700;
                //     display: inline-block;
                //     padding: 20px 40px;
                //     border-radius: 100px;
                //     background-color: #EE4B4C;
                // }
            }
        }

        &-share {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            column-gap: 42px;
            row-gap: 22px;
            text-align: left;

            &-img {
                flex: 0 1 400px;

                img {
                    width: 100%;
                    max-width: 400px;
                    border-radius: 20px;
                }
            }

            &-content {
                flex: 1;
            }

            &-header {
                font-size: 24px;
                font-weight: 700;
                font-style: normal;
                margin-bottom: 16px;

                @media screen and (min-width: 768px) {
                    font-size: 30px;
                }
            }

            &-body {
                font-size: 16px;
                font-weight: 300;
                font-style: normal;
                line-height: 36px;
                margin-bottom: 32px;

                @media screen and (min-width: 768px) {
                    font-size: 22px;
                }
            }

            &-btn {
                display: block;
                
                .btn {
                    width: 100%;
                }

                @media screen and (min-width: 768px) {
                    display: none;
                }
            }

            &-links {
                display: none;
                font-size: 18px;
                font-weight: 600;
                font-style: normal;
                margin-left: -32px;

                @media screen and (min-width: 768px) {
                    display: flex;
                    flex-wrap: wrap;
                    row-gap: 10px;
                }

                a {
                    text-decoration: underline;
                }

                svg {
                    color: $link-color;
                }
            }

            &-link {
                margin-left: 32px;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        a.btn {
            color: #fff;
            font-size: 21px;
            line-height: 21px;
            font-weight: 700;
            font-style: normal;
            border-radius: 65px;
            padding: 18px 34px;
            background: $pledge-btn-color;
            appearance: none;
            box-shadow: none;
            outline: none;
            border: none;

            &:hover {
                color: #fff;
                text-decoration: none;
                background: darken($pledge-btn-color, 30%);
            }
        }

        .btn {
            color: #fff;
            font-size: 21px;
            line-height: 21px;
            font-weight: 700;
            font-style: normal;
            border-radius: 65px;
            padding: 18px 34px;
            background: $pledge-btn-color;
            appearance: none;
            box-shadow: none;
            outline: none;
            border: none;
            display: inline-block;

            &:hover {
                background: darken($pledge-btn-color, 30%);
            }

            svg {
                width: 18px;
                height: 18px;
            }

            &.btn--loading {
                #superHeroPledgeFormSubmitIcon {
                    display: none;
                }

                #superHeroPledgeFormLoadingIcon {
                    display: inline-block;
                }
            }

            #superHeroPledgeFormSubmitIcon {
                display: inline-block;
            }

            #superHeroPledgeFormLoadingIcon {
                display: none;
            }
        }
    }
}