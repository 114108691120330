body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.SuperHeroPledge--App {
  color: #221f1f;
  background-color: #fff;
  border-radius: 12px;
  padding: 40px 24px;
  line-height: 1.2;
  box-shadow: 0 0 8px rgba(0, 0, 0, .15);
  font-family: motiva-sans !important;
}

@media screen and (min-width: 992px) {
  .SuperHeroPledge--App {
    padding: 75px 160px;
  }
}

.SuperHeroPledge--App * {
  box-sizing: border-box;
}

.SuperHeroPledge--App a {
  color: #027a88;
  text-decoration: none;
}

.SuperHeroPledge--App a:hover {
  color: #000;
  text-decoration: underline;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--App .hidden-desktop {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  .SuperHeroPledge--App .hidden-mobile {
    display: none;
  }
}

.SuperHeroPledge--App-layout {
  flex-direction: column;
  align-items: center;
  gap: 24px 42px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--App-layout {
    flex-direction: row;
  }
}

.SuperHeroPledge--App-layoutDivider {
  background-color: rgba(0, 0, 0, .2);
  align-self: stretch;
  width: 100%;
  height: 1px;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--App-layoutDivider {
    height: unset;
    width: 1px;
  }
}

.SuperHeroPledge--App-header {
  margin-bottom: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--App-header {
    font-size: 36px;
  }
}

.SuperHeroPledge--App-subHeader {
  margin: 0 0 48px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--App-subHeader {
    font-size: 22px;
    line-height: 36px;
  }
}

.SuperHeroPledge--App-subHeader strong {
  font-weight: 700;
}

.SuperHeroPledge--App-subHeader ul {
  margin: 20px 0;
  padding-left: 30px;
  list-style: none;
}

.SuperHeroPledge--App-subHeader ul li {
  position: relative;
}

.SuperHeroPledge--App-subHeader ul li:before {
  content: " ";
  background-image: url("data:image/svg+xml,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\"><!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.--><path fill=\"%23ee4b4c\" d=\"M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z\"/></svg>");
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  margin: 2px 10px 0 0;
  display: block;
  position: absolute;
  right: 100%;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--App-subHeader ul li:before {
    margin: 6px 10px 0 0;
  }
}

.SuperHeroPledge--App-footer {
  text-align: center;
  margin-top: 32px;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--App-footer {
    margin-top: 48px;
    font-size: 15px;
    line-height: 24px;
  }
}

.SuperHeroPledge--Pledge-container {
  flex-direction: column;
  gap: 16px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Pledge-container {
    flex: 0 0 260px;
  }
}

@media screen and (min-width: 1024px) {
  .SuperHeroPledge--Pledge-container {
    flex: 0 0 360px;
  }
}

.SuperHeroPledge--Pledge-container img {
  width: 100%;
  height: auto;
}

.SuperHeroPledge--Form {
  --pledge-gutter-x: 30px;
  --pledge-gutter-y: 30px;
  --pledge-border-radius: 0;
  --pledge-border-width: 1px;
  --pledge-border-color: #ddd;
}

.SuperHeroPledge--Form .row {
  margin-top: calc(-1 * var(--pledge-gutter-y));
  margin-right: calc(-.5 * var(--pledge-gutter-x));
  margin-left: calc(-.5 * var(--pledge-gutter-x));
  flex-wrap: wrap;
  display: flex;
}

.SuperHeroPledge--Form .row > * {
  padding-right: calc(var(--pledge-gutter-x) * .5);
  padding-left: calc(var(--pledge-gutter-x) * .5);
  margin-top: var(--pledge-gutter-y);
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
}

.SuperHeroPledge--Form .col {
  flex: none;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form .col {
    flex: 1 0;
  }
}

.SuperHeroPledge--Form .col-12 {
  flex: none;
  width: 100%;
}

.SuperHeroPledge--Form .form-control {
  color: #221f1f;
  -webkit-appearance: none;
  appearance: none;
  border: var(--pledge-border-width) solid var(--pledge-border-color);
  border-radius: var(--pledge-border-radius);
  background-clip: padding-box;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: block;
}

.SuperHeroPledge--Form-intro {
  margin: 20px 0 42px;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-intro {
    font-size: 22px;
  }
}

.SuperHeroPledge--Form-header {
  margin-bottom: 30px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-header {
    font-size: 36px;
  }
}

.SuperHeroPledge--Form-header hr {
  border-width: medium 2px 2px;
  border-color: currentColor #fe9b8f #fe9b8f;
  border-top-style: none;
  width: 100px;
  margin: 20px auto;
}

.SuperHeroPledge--Form-give-header {
  text-align: center;
  margin: 40px 0;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-give-header {
    font-size: 32px;
  }
}

.SuperHeroPledge--Form-give-wrapper {
  flex-direction: column;
  justify-content: center;
  gap: 20px 40px;
  display: flex;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-give-wrapper {
    flex-direction: row;
  }
}

.SuperHeroPledge--Form-give-col {
  flex-direction: column;
  flex: auto;
  align-items: center;
  display: flex;
}

.SuperHeroPledge--Form-give-img img {
  max-width: 200px;
  height: auto;
}

@media screen and (min-width: 1024px) {
  .SuperHeroPledge--Form-give-img img {
    max-width: 280px;
  }
}

.SuperHeroPledge--Form-give-heading {
  color: #333;
  text-align: center;
  margin: 16px 0 8px;
  font-size: 24px;
  font-weight: 700;
}

@media screen and (min-width: 1024px) {
  .SuperHeroPledge--Form-give-heading {
    margin: 24px 0 8px;
    font-size: 32px;
  }
}

.SuperHeroPledge--Form-give-desc {
  text-align: center;
  margin: 0 0 20px;
  font-size: 16px;
  font-weight: 400;
}

.SuperHeroPledge--Form-give-btn {
  margin-top: auto;
}

.SuperHeroPledge--Form-share {
  text-align: left;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 22px 42px;
  display: flex;
}

.SuperHeroPledge--Form-share-img {
  flex: 0 400px;
}

.SuperHeroPledge--Form-share-img img {
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
}

.SuperHeroPledge--Form-share-content {
  flex: 1;
}

.SuperHeroPledge--Form-share-header {
  margin-bottom: 16px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-share-header {
    font-size: 30px;
  }
}

.SuperHeroPledge--Form-share-body {
  margin-bottom: 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-share-body {
    font-size: 22px;
  }
}

.SuperHeroPledge--Form-share-btn {
  display: block;
}

.SuperHeroPledge--Form-share-btn .btn {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-share-btn {
    display: none;
  }
}

.SuperHeroPledge--Form-share-links {
  margin-left: -32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  display: none;
}

@media screen and (min-width: 768px) {
  .SuperHeroPledge--Form-share-links {
    flex-wrap: wrap;
    row-gap: 10px;
    display: flex;
  }
}

.SuperHeroPledge--Form-share-links a {
  text-decoration: underline;
}

.SuperHeroPledge--Form-share-links svg {
  color: #027a88;
}

.SuperHeroPledge--Form-share-link {
  margin-left: 32px;
}

.SuperHeroPledge--Form-share-link:hover {
  cursor: pointer;
}

.SuperHeroPledge--Form a.btn {
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background: #ee4b4c;
  border: none;
  border-radius: 65px;
  outline: none;
  padding: 18px 34px;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
}

.SuperHeroPledge--Form a.btn:hover {
  color: #fff;
  background: #920e0f;
  text-decoration: none;
}

.SuperHeroPledge--Form .btn {
  color: #fff;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  background: #ee4b4c;
  border: none;
  border-radius: 65px;
  outline: none;
  padding: 18px 34px;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  display: inline-block;
}

.SuperHeroPledge--Form .btn:hover {
  background: #920e0f;
}

.SuperHeroPledge--Form .btn svg {
  width: 18px;
  height: 18px;
}

.SuperHeroPledge--Form .btn.btn--loading #superHeroPledgeFormSubmitIcon {
  display: none;
}

.SuperHeroPledge--Form .btn.btn--loading #superHeroPledgeFormLoadingIcon, .SuperHeroPledge--Form .btn #superHeroPledgeFormSubmitIcon {
  display: inline-block;
}

.SuperHeroPledge--Form .btn #superHeroPledgeFormLoadingIcon {
  display: none;
}

/*# sourceMappingURL=index.css.map */
